export default function loadPaymentButton() {
  const makePaymentButton = document.getElementById('make-payment-button')

  if (makePaymentButton) {
    makePaymentButton.addEventListener('click', function (event) {
      let requiredFields = document.querySelectorAll('input[required="required"], select[required="required"]');
      let formOk = true;

      for (var i = 0; i < requiredFields.length; i++) {
        if (!requiredFields[i].validity.valid) {
          formOk = false;
          break;
        }
      }

      if(!formOk) {
        // let form validation kick in
      } else {
        CollectJS.startPaymentRequest(event) 
      }
    }, false)
  }
}
