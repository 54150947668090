// Third Party Scripts
require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require('jquery')

//  Custom Scripts
require("custom/global")
require("custom/nmi_payments")
require("custom/sliding_divs")

import '../stylesheets/application'
import loadPaymentButton from 'custom/nmi_payments'
import slidingDivs from 'custom/sliding_divs'
import 'bootstrap'

document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip()
    $('[data-toggle="popover"]').popover()

    loadPaymentButton();
    slidingDivs();
})