export default function slidingDivs() {
    (function($) {
        "use strict";
        $('#closeSideDiv').click(function() {
            sideDivOut()
        });

        $('#overlayDiv').click(function() {
            sideDivOut()
        });

        $('#privacyLink').click(function() {
            sideDivIn()
            document.getElementById("privacyDiv").classList.remove("hidden");
            document.getElementById("termsDiv").classList.add("hidden");
        });

        $('#privacyBtn').click(function() {
            document.getElementById("privacyDiv").classList.remove("hidden");
            document.getElementById("termsDiv").classList.add("hidden");
        });

        $('#termsLink').click(function() {
            sideDivIn()
            document.getElementById("termsDiv").classList.remove("hidden");
            document.getElementById("privacyDiv").classList.add("hidden");
        });

        $('#termsBtn').click(function() {
            document.getElementById("termsDiv").classList.remove("hidden");
            document.getElementById("privacyDiv").classList.add("hidden");
        });

        function sideDivIn(){
            document.body.classList.add("noscroll");

            document.getElementById("overlayDiv").classList.remove("hidden");

            document.getElementById("sideDiv").classList.remove("animate__slideOutLeft");
            document.getElementById("sideDiv").classList.remove("hidden");
            document.getElementById("sideDiv").classList.add("animate__slideInLeft");
        };

        function sideDivOut(){
            document.body.classList.remove("noscroll");
            
            document.getElementById("overlayDiv").classList.add("hidden");
            
            document.getElementById("sideDiv").classList.remove("animate__slideInLeft");
            document.getElementById("sideDiv").classList.add("animate__slideOutLeft");
        };

        $('#memberToggle').click(function() {
            var toggleValue = document.getElementById("memberToggle").text
            console.log(toggleValue)
            if (toggleValue == 'Non-Member'){
                document.getElementById("profile_member_id").disabled = true;
                document.getElementById("profile_member_id").value = '';
                document.getElementById("member_check").value = false;
                console.log(document.getElementById("member_check").value);
                document.getElementById("memberToggle").text = "Member";
            }else{
                document.getElementById("profile_member_id").disabled = false;
                document.getElementById("member_check").value = true;
                console.log(document.getElementById("member_check").value);
                document.getElementById("memberToggle").text = "Non-Member";
            }
        });
    })(jQuery);
};